<template>
    <section id="reviews">
        <h2>Reviews</h2>
        <div class="reviews-container">
            <div class="row">
                <div class="col-lg-3">
                    <div id="review_summary">
                        <strong>{{avrating}}</strong>
                        <div class="rating">
                            <i class="icon_star" :class="{ voted: avrating>0&&avrating>=1 }" v-if="avrating>=1"></i>
                            <i class="icon_star-half_alt" :class="{ voted: avrating>0&&avrating<1 }" v-if="avrating<1"></i>

                            <i class="icon_star" :class="{ voted: avrating>1&&avrating>=2 }" v-if="avrating>=2"></i>
                            <i class="icon_star-half_alt" :class="{ voted: avrating>1&&avrating<2 }" v-if="avrating<2"></i>

                            <i class="icon_star" :class="{ voted: avrating>2&&avrating>=3 }" v-if="avrating>=3"></i>
                            <i class="icon_star-half_alt" :class="{ voted: avrating>2&&avrating<3 }" v-if="avrating<3"></i>

                            <i class="icon_star" :class="{ voted: avrating>3&&avrating>=4 }" v-if="avrating>=4"></i>
                            <i class="icon_star-half_alt" :class="{ voted: avrating>3&&avrating<4 }" v-if="avrating<4"></i>

                            <i class="icon_star" :class="{ voted: avrating>4&&avrating==5 }" v-if="!(avrating>4&&avrating<5)"></i>
                            <i class="icon_star-half_alt" :class="{ voted: avrating>4&&avrating<5 }" v-if="avrating>4&&avrating<5"></i>
                        </div>
                        <small>  Based on {{starsNumber}} reviews (<i class="icon_like"></i> {{allLikesNumber}})</small>
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-lg-10 col-9">
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" :style="'width: '+five+'%'" :aria-valuenow="five" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-3"><small><strong>5 stars</strong></small></div>
                    </div>
                    <!-- /row -->
                    <div class="row">
                        <div class="col-lg-10 col-9">
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" :style="'width: '+four+'%'" :aria-valuenow="four" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-3"><small><strong>4 stars</strong></small></div>
                    </div>
                    <!-- /row -->
                    <div class="row">
                        <div class="col-lg-10 col-9">
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" :style="'width: '+three+'%'" :aria-valuenow="three" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-3"><small><strong>3 stars</strong></small></div>
                    </div>
                    <!-- /row -->
                    <div class="row">
                        <div class="col-lg-10 col-9">
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" :style="'width: '+two+'%'" :aria-valuenow="two" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-3"><small><strong>2 stars</strong></small></div>
                    </div>
                    <!-- /row -->
                    <div class="row">
                        <div class="col-lg-10 col-9">
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" :style="'width: '+one+'%'" :aria-valuenow="one" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-3"><small><strong>1 stars</strong></small></div>
                    </div>
                    <!-- /row -->
                </div>
            </div>
            <!-- /row -->
        </div>

        <hr>

        <!-- <div class="reviews-container">

            <div class="review-box clearfix" v-for="v in reviews" :key="v">
                <figure class="rev-thumb"><img src="http://via.placeholder.com/150x150/ccc/fff/avatar1.jpg" alt="">
                </figure>
                <div class="rev-content">
                    <p>
                        {{v.user[0].firstName}} {{v.user[0].lastName}}
                    </p>
                    <div class="rating">
                        <i class="icon_star" :class="{ voted: v.star>0||v.star==1 }"></i>
                        <i class="icon_star" :class="{ voted: v.star>1||v.star==2 }"></i>
                        <i class="icon_star" :class="{ voted: v.star>2||v.star==3 }"></i>
                        <i class="icon_star" :class="{ voted: v.star>3||v.star==4 }"></i>
                        <i class="icon_star" :class="{ voted: v.star>4||v.star==5 }"></i>
                    </div>
                    <div class="rev-info">
                        {{v.createdAt}}
                    </div>
                    <div class="rev-text">
                        <p>
                            {{v.reviewMessage}}
                        </p>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="reviews-container">

            <div class="review-box clearfix" v-for="v in reviews" :key="v">
                <figure class="rev-thumb">
                    <ProfileImage :id="v.user[0]._id" :name="v.user[0].imageFile"  />
                </figure>
                <div class="rev-content">
                    <p>
                        {{v.user[0].firstName}} {{v.user[0].lastName}}
                    </p>
                    <div class="rating">
                        <i class="icon_star" :class="{ voted: v.star>0||v.star==1 }"></i>
                        <i class="icon_star" :class="{ voted: v.star>1||v.star==2 }"></i>
                        <i class="icon_star" :class="{ voted: v.star>2||v.star==3 }"></i>
                        <i class="icon_star" :class="{ voted: v.star>3||v.star==4 }"></i>
                        <i class="icon_star" :class="{ voted: v.star>4||v.star==5 }"></i>
                    </div>
                    <div class="rev-info">
                        {{v.createdAt}}
                    </div>
                    <div class="rev-text">
                        <p>
                            {{v.reviewMessage}}
                        </p>
                    </div>
                </div>
            </div>
            <!-- /review-box -->
        </div>
        <!-- /review-container -->
    </section>										
</template>
<script>
import ProfileImage from "@/components/ProfileImage";
import { mapActions } from "vuex";
export default {
    name: 'Rating',
	props: {
        id: String,
        type: String,
        like: Boolean
    },
    components: {
        ProfileImage
    },
    data(){
		return{
            alreadyReviewed: true,
            reviews: '',
            stars: '',
            starsNumber: '',
            avrating: '',
            allLikes: '',
            one: '',
            two: '',
            three: '',
            four: '',
            five: '',
            allLikesNumber: '',
        }
    },
    methods: {
        ...mapActions(['fetchReviewedPublic']),
        checkStar(star){
            let total = 0;
            let answer = 0;
            if (this.stars.length == 0) {
                answer = 0;
            }else{
                for (let i = 0; i < this.stars.length; i++) {
                    const element = this.stars[i];
                    if (element.star == star) {
                        total++;
                    }
                }
                
                answer = (total/this.stars.length)*100;
            }
            return answer.toFixed(1);
        },
        checkAveageStar(){
            let total = 0;
            let answer = 0;
            if (this.stars.length == 0) {
                answer = 0;
            }else{
                for (let i = 0; i < this.stars.length; i++) {
                    const element = this.stars[i];
                    total = element.star + total
                }
                answer = total/this.stars.length;
            }
            return answer.toFixed(1);
        },
        countlikes(){
            let total = { like : 0, dislike : 0};
            for (let i = 0; i < this.stars.length; i++) {
                const element = this.stars[i];
                if (element.like == 1) {
                    total.like++
                }else{
                    total.dislike++
                }
            }
            return total;
        },
        fetchingReviews(type){
            let reviewObject= { 
				id : this.id,
				type : type
            }
			this.fetchReviewedPublic(reviewObject)
			.then(res => {
				this.alreadyReviewed = res.data.alreadyReviewed
				this.reviews = res.data.Reviews
                this.stars = res.data.stars
                this.starsNumber = this.stars.length
                this.one = this.checkStar(1)
				this.two = this.checkStar(2)
				this.three = this.checkStar(3)
				this.four = this.checkStar(4)
				this.five = this.checkStar(5)
                this.avrating = this.checkAveageStar()
                this.allLikes = this.countlikes()
                this.allLikesNumber = this.allLikes.like
                if (this.avrating==0) {
                    this.useDummyRatings()
                }
			})
        },
        useDummyRatings(){
            var splitted = this.id.split("");
            var totalRatings = 1
            var totalLikes = 1
            for (let i = 0; i < splitted.length; i++) {
                const element = splitted[i];
                if (this.checkCharacter(element)) {
                    totalLikes++
                    if (i<=9) {
                        totalRatings++
                    }
                }
                
            }
            this.avrating = ((60 + (totalRatings*3))/20).toFixed(1)
            this.starsNumber = totalLikes*6
            this.allLikesNumber = ((this.starsNumber/2)+(this.starsNumber/4)+(this.starsNumber/8)).toFixed(0)
            if (this.avrating>=4.0) {
              this.allLikesNumber = ((this.starsNumber/2)+(this.starsNumber/4)+(this.starsNumber/8)+(this.starsNumber/16)).toFixed(0)  
            }
            this.setStarLevels(this.avrating)
        },
        checkCharacter(character){
            var stringflag = false
            var value = /^\d+$/.test(character)
            if (!value){
                stringflag = true
            }
            if (stringflag) {
                return false
            }else{
                return true
            }
        },
        setStarLevels(avr){
            var one,two,three,four,five
            one = 2
            two = 8
            if (avr>=4) {
                three = 20
                four = 40
                five = 30
            }else{
                three = 25
                four = 45
                five = 20
            }
            this.one = ((one/100) * this.allLikesNumber);
			this.two = ((two/100) * this.allLikesNumber)
			this.three = ((three/100) * this.allLikesNumber)
			this.four = ((four/100) * this.allLikesNumber)
			this.five = ((five/100) * this.allLikesNumber)
        },
    },
    mounted(){
        this.fetchingReviews(this.type)
    }
}
</script>
<style >
 .rating{
     background: rgba(243, 240, 237, 0.4);
     padding: 10px;
     border-radius: 3px;
 }
</style>