<template>
  <div>
   
<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Preview</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <video :src="url" width="640" height="300" style="opacity: 1;" controlsList="nodownload" v-if="url!=''&&url!=null&&fileType!='embedLink'" controls ></video>
        <iframe :src="url+'#zoom=85&scrollbar=0&toolbar=0&navpanes=0'" width="640" height="300" v-if="url!=''&&url!=null&&fileType=='embedLink'" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data(){
    return{
      url: '',
    }
  },
  props: {
    fileName: String,
    fileType: String
  },
  watch: {
		fileName: function() {
      this.url = null
      if (this.fileType=='embedLink') {
        this.url = this.fileName
      }else{
        console.log(this.fileType)
        this.generate_V4ReadSignedUrl_public(this.fileName)
        .then(res => {
          this.url = res.data
        })
      }

		},
		

	},
  methods:{
    ...mapActions(['generate_V4ReadSignedUrl_public'])
  },
  mounted(){
    if (this.fileType=='embedLink') {
        this.url = this.fileName
      }else{
        this.generate_V4ReadSignedUrl_public(this.fileName)
        .then(res => {
          this.url = res.data
        })
      }
  }
}
</script>

<style>
#exampleModalCenter{
margin-top:250px;
}
</style>